// $primary: #823b00;
$primary: #006da7;
$info: #4494ac40;
// $secondary: #424242;
// $dark:#002348;
// $font-size-base: 1rem;

// $container-max-widths: (
//   xs: 480px,
// );

@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/bootstrap/scss/bootstrap-utilities.scss";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/carousel";

.carousel-inner {
    height: 100%;
    width: 100%;
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}
